import {msgTips} from '../../axios/path.js';
import {log} from '../../log.js';

const MsgTip = function (ws, event, commandName, bodyStr, bodyObj) {
    log("server message received", commandName, bodyObj);
    let code = bodyObj.code;
    switch (code) {
        case 1:
            log('MsgTip', code);
            // router.push('/login');
            break;
        case  2:
        case 99:
            msgTips(bodyObj.msg);
            break;
    }
};
export default MsgTip;