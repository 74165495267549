import CryptoJS from "crypto-js";
import {commonView, user} from "./lib/axios/path";
import {useEffect} from "react";

function Login() {
    const handleSubmit = () => {
        let token = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIzNDAwODczIiwiYXBwX3R5cGUiOjEsInV0bV9jYW1wYWlnbiI6bnVsbCwidXRtX21lZGl1bSI6bnVsbCwic291cmNlIjoiRlFBIiwiY2xpZW50X2lkIjoiZnFhLndlYi5hcHAiLCJwaWN0dXJlIjoiIiwiYWNjb3VudF9pZCI6IjM0MDA4NzMiLCJvc190eXBlIjo0LCJzY29wZSI6WyJmcWE6ZnVsbCIsImZxYTpjbGllbnQ6cG9zdCIsImZxYTpjbGllbnQ6Z2V0Il0sIm5hbWUiOiIxOTAwMDAwMTA2MSIsImV4cCI6MTY4NDk5OTg0MSwidXRtX3NvdXJjZSI6bnVsbCwidXNlcm5hbWUiOiIxOTAwMDAwMTA2MSJ9.pfqJT8Tmu0HDRuhLulAidrziEYiht9wDronc32AnQI8';
        user.userLoginFQA({headers: {Authorization: token}}).then((res) => {
            console.log(res);

            if (res.ok) {
                window.location.href = '/';
            } else {
                console.log(res.msg)
            }
        });

        // let { loginName, passWord, authcode } = { loginName: '19000001003', passWord: 'U19000001003', authcode: '' };
        // //防止build后不能正确拼接的问题
        // let key1 = "$",
        //     key2 = "{",
        //     key3 = "}";
        // let palinstr = `${key1}${key2}${loginName}${key3}${passWord}`;
        // let pd5 = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(palinstr)).toString();
        // let postdata = { loginname: loginName, pd5: pd5,authcode };
        // user.userLogin(postdata).then((res) => {
        //     if (res.ok) {
        //         window.location.href = '/';
        //     } else {
        //         console.log(res.msg)
        //     }
        // })
    };

    return (
        <div>
            <h1>Login</h1>

            <form onSubmit={handleSubmit}>
                <input type="submit" />
            </form>
        </div>
    );
}

export default Login;
