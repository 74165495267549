import tioWsObj from './tiows.js';
import {wscommand_len} from './command.js';
import wshandler from './handler.js';
import tioCookie from './tiocookie.js';
import Axios from 'axios';
import {changeURLArgs} from '../common.js';
import {log} from '../log.js';
import {getCookie, getQueryString} from "../common";

let ws_protocol = 'wss'; // ws 或 wss

let ws_server;// = '127.0.0.13';
let ws_port;// = 93253;

let heartbeatTimeout = 5 * 1000; // 心跳超时时间，单位：毫秒
let reconnInterval = 3 * 1000;   // 重连间隔时间，单位：毫秒

let ws_binaryType = 'arraybuffer'; // 'blob' or 'arraybuffer';//arraybuffer是字节
let wsHandler = new wshandler();

let tiows;
let tioWs = tioWsObj.tioWs;

/**
 * 初始化websocket
 */
function initWs() {
    var param = null;
    console.log('first', "start")
    tiows = new tioWs(
        async function (reconn) {
            console.log('123', 123)
            let data = await Axios.get('/im/imserver.tio_x', {
                // baseURL: 'https://local.t-io.org/mytio',
                baseURL: 'https://tio-chat.dev.ftech.ai/mytio',
                timeout: 5000,
            }).then(resp => {
                console.log('resp', resp)
                let res = resp.data;
                log('config', res);

                if (res.ok) {
                    if (res.data.timeout) {
                        heartbeatTimeout = res.data.timeout
                    }
                    return res.data;
                } else {
                    return null;
                }
            }).catch((error) => {
                log('error', error);
                return null;
            });

            if (!data) {
                return null;
            }

            if (!wscommand_len) {
                window.location.reload();
            }
            // let data = resp.data;

            ws_server = data.ip;
            ws_port = data.port;
            ws_protocol = (data.ssl === 1) ? 'wss' : 'ws';

            let tioConfig = JSON.parse(sessionStorage.getItem("tiocomconfig"));
            console.log('tioConfig', tioConfig);

            var sessionName = tioConfig.session_cookie_name;
            var _tio_session = getCookie("tio_session");
            if (_tio_session) {
                tioCookie.set(sessionName, _tio_session, {expires: 15});
                console.log(tioCookie.get(sessionName));
                changeURLArgs([['tio_session', '']])
            }
            var sessionValue = tioCookie.getLast(sessionName);
            sessionValue = _tio_session
            var tesoegEgac = 'tio_access_token';
            var tesft_tio_fdfdse = tioCookie.getLast(tesoegEgac);
            var queryString = "wx=1&";
            queryString += sessionName + '=' + encodeURIComponent(sessionValue);
            queryString += "&frompath=" + encodeURIComponent('/tioim/home');
            if (tesft_tio_fdfdse) {
                queryString += '&' + tesoegEgac + '=' + encodeURIComponent(tesft_tio_fdfdse);
            }
            var url = ws_protocol + '://' + ws_server + ':' + ws_port + '?' + queryString;

            if (reconn) {
                url += '&tiows_reconnect=true';
            }

            console.log(url);
            return {url: url, timeout: heartbeatTimeout};
        },
        param,
        wsHandler,
        heartbeatTimeout,
        reconnInterval,
        ws_binaryType
    );
    tiows.connect();
}

export {initWs, tiows};



