import CryptoJS from 'crypto-js'

/**
 * @word what to encrypt
 * @keyWord String  The keyword randomly returned by the server
 */
export function aesEncrypt(word, keyWord = "XwKsGlMcdPMEhR1B") {
    const key = CryptoJS.enc.Utf8.parse(keyWord);
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
}