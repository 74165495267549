/*
 * b执行此方法
 * a向b发起通话请求
 */
import wsSend from '../send';//发送消息方法
import {wscommand} from '../command';//消息码
import store from '../../store';
import {callSetSomeValue, hasAudioinput, hasVideoinput} from '../../call';
import {resUrl} from '../../common';
import {msgTips} from '../../axios/path.js';
import {log} from '../../log.js';


let wxCallLocalAudio;
var WxCall02Ntf = async function (ws, event, commandName, bodyStr, bodyObj) {
    log('WxCall02Ntf: ', commandName, bodyObj);

    var req = {};
    callSetSomeValue(req, bodyObj);  //透传填值

    let check = true;
    if (bodyObj.type == 1) {
        let hasaudio = await hasAudioinput();
        if (!hasaudio) {
            check = false;
        }
    }
    if (bodyObj.type == 2) {
        let hasvideo = await hasVideoinput();
        if (!hasvideo) {
            check = false;
        }
    }

    if (!check) {
        req.result = 3;  //1、同意通话；2、拒接；3、没有通话设备
        wsSend(wscommand.WxCall03ReplyReq, req);
        return;
    }

    store.commit('setCallType', bodyObj.type);//通话类型  1：音频；2：视频
    let friendInfo = await store.dispatch('getUserInfo', bodyObj.fromuid);//发起方信息

    store.commit('setRemoteReq', req);//接收方发送消息时的参数
    //获取发送方信息
    friendInfo.avatar = resUrl(friendInfo.avatar);
    store.commit("setCallInfo", friendInfo);
    store.commit('setCallShow', true);
    store.commit('setCallRole', 2);

    wxCallLocalAudio.muted = true;//设置本地音频通话静音
};
export default WxCall02Ntf;