import {commonView, currUser, user, friend, chatcom} from "./axios/path";
import {initWs} from './ws/ws'
import CryptoJS from "crypto-js";
import {aesEncrypt} from "./utils/ase";


const userWrapper = {
    userLogin({username, password, authcode}) {
        const key1 = "$";
        const key2 = "{";
        const key3 = "}";
        let palinstr = `${key1}${key2}${username}${key3}${password}`;
        let pd5 = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(palinstr)).toString();
        if (typeof authcode != 'undefined') {
            pd5 = ''
        }
        let postdata = {loginname: username, pd5: password, authcode};
        return user.userLogin(postdata);
    },
    userLoginFQA(token) {
        return user.userLoginFQA({headers: {Authorization: 'Bearer ' + token}});
    },
    userRegister({username, password, nick, mobileCode}) {
        const key1 = "$";
        const key2 = "{";
        const key3 = "}";
        let palinstr = `${key1}${key2}${username}${key3}${password}`;
        let pwd = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(palinstr)).toString();

        let postdata = {
            loginname: username,
            pwd: pwd,
            code: mobileCode,
            nick,
            agreement: "on",
        };
        return user.userRegister(postdata);
    },
    logout() {
        return user.logout();
    },
    reqGetSlide() {
        let data = {captchaType: 'blockPuzzle'};
        return user.reqGet(data);
    },
    reqGetPoint() {
        let data = {captchaType: 'clickWord'};
        return user.reqGet(data);
    },
    reqCheckSlide({moveLeftDistance, secretKey, backToken}) {
        let data = {
            captchaType: 'blockPuzzle',
            "pointJson": secretKey ? aesEncrypt(JSON.stringify({
                x: moveLeftDistance,
                y: 5.0
            }), secretKey) : JSON.stringify({x: moveLeftDistance, y: 5.0}),
            "token": backToken
        };
        return user.reqCheck(data);
    },
    reqCheckPoint({checkPosArr, secretKey, backToken}) {
        let data = {
            captchaType: 'clickWord',
            "pointJson": secretKey ? aesEncrypt(JSON.stringify(checkPosArr), secretKey) : JSON.stringify(checkPosArr),
            "token": backToken
        };
        return user.reqCheck(data);
    },
    smsSend({biztype, mobile, captchaVerification}) {
        let data = {biztype: biztype, mobile: mobile, captchaVerification: captchaVerification};
        return user.smsSend(data);
    },
    smsBeforeCheck(biztype, mobile) {
        let data = {
            biztype,
            mobile
        };
        return user.smsBeforeCheck(data);
    },
    smsCheck(biztype, mobile, code) {
        return user.smsCheck({biztype, mobile, code})
    },
};

const friendWrapper = {
    getApplyData() {
        return friend.getApplyData();
    }
};

const chatcomWrapper = {
    chatRecent() {
        return chatcom.chatRecent();
    }
};

export {
    commonView,
    currUser,
    userWrapper as user,
    friendWrapper as friend,
    chatcomWrapper as chatcom,
    initWs
}