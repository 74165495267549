import "./App.css";
import {getCookie} from "./lib/common";
import {commonView, currUser, user, friend, chatcom, initWs} from "./lib";
import {useEffect, useState} from "react";
import wsSend from "./lib/ws/send";
import store from './lib/store';
import {wscommand} from "./lib/ws/command";
import {msgTips} from "./lib/axios/path";

function App() {
    const [userCurrent, setUserCurrent] = useState(undefined);
    const [chatRecents, setChatRecents] = useState([]);
    const [chatSelect, setchatSelect] = useState(undefined);
    const [message, setMessage] = useState('');

    let stateMessageList = store;

    console.log('stateMessageList', stateMessageList)


    useEffect(() => {
        const fetchData = async () => {
            // get the data from the api
            const viewData = await commonView();
            console.log('viewData', viewData);

            let viewConfig;
            if (viewData.ok) {
                viewConfig = viewData.data;
                sessionStorage.setItem('tiocomconfig', JSON.stringify(viewConfig));
            }
        };
        fetchData();
    }, []);

    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    // function getCookie(name) {
    //     var nameEQ = name + "=";
    //     var ca = document.cookie.split(';');
    //     for(var i=0;i < ca.length;i++) {
    //         var c = ca[i];
    //         while (c.charAt(0)==' ') c = c.substring(1,c.length);
    //         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    //     }
    //     return null;
    // }
    function eraseCookie(name) {   
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    useEffect(() => {
        console.log('ádasasdasd', stateMessageList)
        // setCookie("tio_session", "1768194332680134656")
        // initWs();
    }, [stateMessageList]);

    const onClickLogin = async () => {
        let loginName = '3402747';
        let passWord = '4793423e2de915489556deb4daf23a97';

        const userLogin = await user.userLogin({username: loginName, password: passWord});
        console.log('login: ', userLogin);
        if (userLogin.ok) {
            let userRes = await currUser();
            console.log('currUser: ', userRes);
            if (userRes.ok) {
                setUserCurrent(userRes.data);
            }

            store.commit('setIsConnect', true);

            let getApplyData = await friend.getApplyData();
            console.log('getApplyData: ', getApplyData);

            let chatRecent = await chatcom.chatRecent();
            console.log('chatRecent: ', chatRecent);

            if (chatRecent.ok) {
                setChatRecents(chatRecent.data);

                if (chatRecent.data.length) {
                    setchatSelect(chatRecent.data[0]);
                    store.commit('setChatOn', {
                        chatOn: chatRecent.data[0].id,
                        bizid: chatRecent.data[0].bizid,
                        isGroup: false
                    })
                }
            }

            initWs();
        }
    };

    const sendMsg = (event) => {
        event.preventDefault();

        let sendVal = message;
        console.log('message', message)
        if (sendVal == "") {
            msgTips("Please enter the text content");
            return
        }
        //取出输入内容中的艾特
        let atarr = [];
        if (store.state.Ws.isGroup) {
            // let atlist = $("#chat-editor .setatbutton");
            let atlist = [];
            if (atlist.length > 0) {
                // $.each(atlist, function (v, item) {
                //     atarr.push($(item).attr("atuid"))
                // })
            }
            atarr = Array.from(new Set(atarr))
        }

        // this.$chatEditor.html("") //清空输入内容
        //消息三方共用-将消息中的html标签处理
        sendVal = sendVal.replace(/<br>/gm, "\n");

        var regx = /<[^>]*>|<\/[^>]*>/gm;
        sendVal = sendVal.replace(regx, "");
        sendVal = sendVal.replace(/&nbsp;/gm, "  ");
        sendVal = sendVal.substring(0, 2000); //控制发送字数
        console.log('store.state.Ws', store.state.Ws)
        if (store.state.Ws.isGroup) {
            let sendreq = {
                c: sendVal,
                chatlinkid: store.state.Ws.chatOn,
            };
            if (atarr.length > 0) {
                sendreq.at = atarr.join(",")
            }
            wsSend(wscommand.WxGroupChatReq, sendreq)
        } else {
            let sendreq = {
                c: sendVal,
                chatlinkid: store.state.Ws.chatOn,
            };
            wsSend(wscommand.WxFriendChatReq, sendreq)
        }
    };


    console.log('tio_session: ', getCookie('tio_session'));
    return (
        <div className="App">

            <div className={'container'}>
                <div className={'row'}>
                    <h1>Tio SDK Demo</h1>
                </div>

                <div className={'row'}>
                    <button onClick={onClickLogin}>Login</button>
                </div>

                <div className={'row'} style={{marginTop: '100px', border: '3px solid black'}}>
                    <div className="col-lg-12">
                        <div className="card chat-app">
                            <div id="plist" className="people-list">
                                <ul className="list-unstyled chat-list mt-2 mb-0">
                                    {chatRecents.map((chatRecent, index) => {
                                        if (chatSelect !== undefined && chatSelect.id === chatRecent.id) {
                                            return (<li key={chatRecent.id} className="clearfix active">
                                                <img src={chatRecent.avatar} alt="avatar"/>
                                                <div className="about">
                                                    <div className="name">{chatRecent.name}</div>
                                                    <div className="status"><i className="fa fa-circle offline"/> left 7
                                                        mins ago
                                                    </div>
                                                </div>
                                            </li>)
                                        } else {
                                            return (<li key={chatRecent.id} className="clearfix">
                                                <img src={chatRecent.avatar} alt="avatar"/>
                                                <div className="about">
                                                    <div className="name">{chatRecent.name}</div>
                                                    <div className="status"><i className="fa fa-circle offline"/> left 7
                                                        mins ago
                                                    </div>
                                                </div>
                                            </li>)
                                        }
                                    })}
                                </ul>
                            </div>
                            <div className="chat">
                                <div className="chat-header clearfix">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <a href="#" data-toggle="modal" data-target="#view_info">
                                                <img
                                                    src={chatSelect === undefined ? 'https://img.freepik.com/free-icon/user_318-563642.jpg?size=626&ext=jpg' : chatSelect.avatar}
                                                    alt="avatar"/>
                                            </a>
                                            <div className="chat-about">
                                                <h6 className="m-b-0">{chatSelect === undefined ? 'Anonymous' : chatSelect.name}</h6>
                                                <small>Last seen: 2 hours ago</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 hidden-sm text-right">
                                            <a href="#" className="btn btn-outline-secondary"><i
                                                className="fa fa-camera"/></a>
                                            <a href="#" className="btn btn-outline-primary"><i className="fa fa-image"/></a>
                                            <a href="#" className="btn btn-outline-info"><i className="fa fa-cogs"/></a>
                                            <a href="#" className="btn btn-outline-warning"><i
                                                className="fa fa-question"/></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-history">
                                    <ul className="m-b-0">
                                        <li className="clearfix">
                                            <div className="message-data text-right">
                                                <span className="message-data-time">10:10 AM, Today</span>
                                            </div>
                                            <div className="message other-message float-right"> Hi Aiden, how are you?
                                                How is the project coming along?
                                            </div>
                                        </li>
                                        <li className="clearfix">
                                            <div className="message-data">
                                                <span className="message-data-time">10:12 AM, Today</span>
                                            </div>
                                            <div className="message my-message">Are we meeting today?</div>
                                        </li>
                                        <li className="clearfix">
                                            <div className="message-data">
                                                <span className="message-data-time">10:15 AM, Today</span>
                                            </div>
                                            <div className="message my-message">Project has been already finished and I
                                                have results to show you.
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="chat-message clearfix">
                                    <div className="input-group mb-0">
                                        <input type="text" className="form-control" value={message} placeholder="Enter text here..." onChange={e => setMessage(e.target.value)}/>
                                        <button onClick={sendMsg}>Send message</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default App;
