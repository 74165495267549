import User from './modules/user'
import Ws from './modules/ws'
import Call from './modules/call'
import CommonInfo from './modules/commonInfo'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        User,
        Ws,
        Call,
        CommonInfo
    }
})

// export default {
//   User,
//   Ws,
//   Call,
//   CommonInfo
// }