import {formatCommand, commandReverse} from './command.js';
import {tiows} from './ws.js';
import {log} from '../log.js'


const wsSend = function (command, bodyObj) {
    var commandstr = formatCommand(command); //-128, 0002, 0012
    var commandname = commandReverse[commandstr]; //HeartbeatReq
    var str = commandstr;
    if (bodyObj) {
        str = commandstr + JSON.stringify(bodyObj);
        log(str + "str");
    }

    var uint8array = new TextEncoder().encode(str);
    log('wsSend: ' + commandname + ":'" + str + "'", uint8array.buffer);
    console.log('tiows', tiows)
    tiows.ws.send(uint8array.buffer);
};
export default wsSend;