import axios from 'axios';
import {log} from '../log';
import qs from 'qs';

/* 自定义axios实例 */
let instance = axios.create({
    // baseURL: "https://local.t-io.org/mytio",
    baseURL: 'https://tio-chat.dev.ftech.ai/mytio',
    // baseURL: 'http://192.168.8.106:6060/mytio',
    withCredentials: true
});
let suffix = ".tio_x";

let _appcode_start = 1000;
let appcode = {
    NOTLOGIN: 1 + _appcode_start,  //没有登录
    TIMEOUT: 2 + _appcode_start,//登录超时
    KICKTED: 3 + _appcode_start,// 帐号在其它地方登录
    NO_GRANT: 99 + _appcode_start,// 未授权
};
//添加响应拦截器
instance.interceptors.response.use(function (response) {
    let code = response.data.code;
    // let currpath = router.history.current.path;
    // if(currpath!='/login'&&currpath!='/'){
    //     if(code==appcode.NOTLOGIN||code==appcode.TIMEOUT||code==appcode.KICKTED){
    //         location.reload();
    //         router.push("/login");
    //     }
    // }
    if (code === appcode.NO_GRANT) {
        window.location.replace(`/2/grant/client/client.html?redirect_uri_after_grant=${encodeURIComponent(window.location.href)}`);
        return;
    }
    return response;
});

/* post */
export function fetchPost(url, data, config) {
    url = url + suffix;
    if (typeof data == 'object') {
        data = qs.stringify(data);
    }

    return new Promise((resolve, reject) => {
        instance.post(url, data, config).then(response => {
            resolve(response.data);
        }).catch((error) => {
            log(error);
            reject(error);
        })
    })
}

export function fetchPostLogin(url, data, config) {
    url = url + suffix;
    if (typeof data == 'object') {
        data = qs.stringify(data);
    }

    return new Promise((resolve, reject) => {
        instance.post(url, data, config).then(response => {
            console.log('response', response.headers)
            resolve(response);
        }).catch((error) => {
            log(error);
            reject(error);
        })
    })
}

/* get */
export function fetchGet(url, param) {
    url = url + suffix;

    return new Promise((resolve, reject) => {
        instance.get(url, {params: param})
            .then(response => {
                resolve(response.data)
            }).catch((error) => {
            console.error(error);
            reject(error);
        })
    })
}

/* 上传文件 */
export function fetchUpload(url, formData) {
    url = url + suffix;
    return new Promise((resolve, reject) => {
        instance.post(url, formData, {
            contentType: false,
            processData: false,
        }).then(response => {
            resolve(response.data)
        }).catch((error) => {
            // reject(error);
            log(error);
        })
    })
}

/* 下载文件 */
export function getFile(url, filename) {
    return new Promise((resolve, reject) => {
        instance.get(url, {responseType: 'arraybuffer'}).then(res => {
            resolve(res.data);
        }).catch((err) => {
            log(err);
        })
    })
}